<template>
  <div class="consensu-container" :style="cssVars" v-if="consent.configs">
    <div class="header">
        <div class="logo">
       
            <div class="title">
            
            <a  @click="setTab(0)">
              Preferências de cookies
            </a>
            </div>
        </div>
        <!-- <div class="actions">
          <div class="title">
            <a  @click="setTab(0)">
              Preferências de cookies
            </a>
            </div>
        </div> -->
        
    </div>
    <div class="body">
      
        <h3>{{ consent.configs.custom.info }}</h3>
         <b-form v-on:submit.prevent class="consensu-cookie-form">
            <div class="consensu-cookie-list accordion" role="tablist" v-if="version.length > 0">
               <template v-for="(item, idx) in version" >
                  <b-card no-body class="mb-1" :key="idx">
                     <b-card-header header-tag="header" class="p-1 text-left" role="tab"   variant="info">
                        <span class="category-name" v-b-toggle="'accordion-' + idx">{{ item.category.name }} ({{ item.cookies.length }})</span>
                        <div class="form-check form-switch switch">
                           <input class="form-check-input" type="checkbox" 
                            :id="`consensu-category-${item.category._id}`"
                            :value="item.category._id"
                            :disabled="item.category.disabled"
                            :checked="selected[item.category._id].cookies.length === item.cookies.length"
                             @click="toggleCategory(item.category._id, $event.target.checked)"
                           >
                           <label class="form-check-label" :for="`consensu-category-${item.category._id}`"></label>
                        </div>
                     </b-card-header>
                     <b-collapse :id="`accordion-${idx}`" accordion="accordion" role="tabpanel">
                        <b-card-body>
                           <b-card-text class="category-description">{{item.category.description}}</b-card-text>
                           <table class="table table-striped">
                              <thead>
                                 <tr>
                                    <th scope="col">Cookie</th>
                                    <th scope="col">Descrição</th>
                                    <th scope="col"></th>
                                 </tr>
                              </thead>
                              <tbody >
                                 <tr v-for="cookie in item.cookies" :key="cookie._id">
                                    <td >
                                       <div class="cookie-name">
                                          <span>{{cookie.name}}</span>
                                          <small>{{cookie.expiration}}</small>
                                       </div>
                                    </td>
                                    <td>{{cookie.description}}</td>
                                    <td>
                                       <div class="form-check form-switch switch">
                                          <input class="form-check-input" type="checkbox"
                                            v-if="selected[item.category._id]"
                                            :value="cookie._id"
                                            v-model="selected[item.category._id].cookies"
                                            :data-cookie-name="cookie.name"
                                            :id="cookie._id"
                                            :disabled="item.category.disabled"
                                            :class="`consensu-category-${item.category._id}-cookie`"
                                          >
                                          <label class="form-check-label" :for="cookie._id"></label>
                                       </div>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                           
                        </b-card-body>
                     </b-collapse>
                  </b-card>
               </template>
               <p class="info-version">
               <span class="version">
                 <b>Identificação Única:</b> {{fingerprint}}
               </span>
               <span class="version">
                 <b>Versão:</b> {{versionID}}
               </span>
               <span class="lastUpdate">
                 <b>Última Atualização:</b> {{ new Date(version[0].lastUpdate).toLocaleString()}}
               </span>
               </p>
                 
            </div>
         </b-form>
    <div class="buttons">
       <button class="btn btn-secondary " @click="hideConsent" >FECHAR</button> 
        <button class="btn btn-primary " @click="saveCustom">
          SALVAR
        </button>
       
        </div>

    </div>
     
    <div class="footer">
      <div class="actions">
        <a :href="consent.configs.terms.url"   class="btn btn-primary"  target="_blank" v-if="consent.configs.terms.url">Termos de uso</a>
        <a :href="consent.configs.privacy.url" class="btn btn-primary"  target="_blank" v-if="consent.configs.privacy.url">Política de Privacidade</a>
        <a :href="consent.configs.cookies.url" class="btn btn-primary"  target="_blank" v-if="consent.configs.cookies.url">Política de Cookies</a>
        <a :href="consent.configs.optOut.url"  class="btn btn-primary"  target="_blank" v-if="consent.configs.optOut.url">Optout</a>        
      </div>
    </div>
  </div>
</template>

<script>
  import mixEvents from "../../mixins/events";
  
  export default {
    name: "box",
    mixins: [mixEvents],
    props: ["consent", "version"],
    components: {},
    data() {
      return {
        tabs: [
          {
            title: "Preferências de cookies",
            comp: this.load("cookies")
          },
        ],
        tabIndex: 0,
        overlayClass: "",
        viewport: {
          360: {
            width: 360,
            height: 380,
          },
          400: {
            width: 400,
            height: 500,
          },
          798: {
            width: 400,
            height: 500,
          },
          800:{
            width: 800,
            height: 700,
          },
        },
      };
    },
    mounted() {
      this.setConfig();
      this.sendEvent("makeConsent", this.consent);
    },
    methods: {
        load(cmp) {
        return () => import(`./comps/${cmp}`);
      },
      setTab(idx) {
        this.tabIndex = idx;
      },
      setConfig() {
        // const width  = window.screen.availWidth 
        // const height = window.screen.availHeight

        console.log(window.screen);
        this.sendEvent("makeViewport", this.viewport);
      },
      custom() {
        this.overlayClass = "open-sign-in";
      },
      back() {
        this.overlayClass = "open-sign-up";
      },
    },
    computed: {},
  };
</script>

<style lang="scss">
  @import "style.scss";
  .logo-consensu{
    height: 30px;
  }
  .title{
    padding: 20px;
    font-weight: bold;
    border-bottom: 0px !important;
    border-radius: 10px 10px 0px 0px;
    text-align: center;
    color: white;
    background-color: var(---secondary-color);
  }
.body h3{
  width:100%;
}
  .buttons{
    justify-content: center;
    width:100%;
    display: flex;
  }
  .buttons button{
    font-weight: bold;
    width: 100px;
    text-transform: uppercase;
    margin:5px;
    background-color: #00ab4e;  
    border-color: #00ab4e;
  }
  .buttons button.btn-secondary{
    background-color: var(---secondary-color);
    border-color: var(---secondary-color);
  }
</style>
